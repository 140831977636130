import dayjs from 'dayjs';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

// MUI imports
import { Box, Tooltip, Typography, IconButton, TextField, Chip, Grid, Stack, Button, FormLabel, CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/material/styles';

// Project imports
import MainCard from 'ui-component/cards/MainCard';
import '../../assets/scss/custom-styles.css';
import LockedTextField from 'ui-component/LockedTextField';
import LockedOrUnlockedTextField from 'ui-component/LockedOrUnlockedTextField';
import SignaturePad from 'signature_pad';
import axios from 'utils/axios';
import { useDispatch } from 'store';
import { showErrorAlert } from 'utils/global-functions';
import statusMap from './coaching/CoachingShared';
import { startOfDay, startOfToday } from 'date-fns';

export default function CoachingLog({ session, closeSession, setLoadingSession }) {
    const signaturePad = useRef();

    const [saving, setSaving] = useState(false);

    const startTime = dayjs(session.startTime);
    const endTime = dayjs(session.endTime);

    const theme = useTheme();
    const dispatch = useDispatch();

    const [hasExistingLog, setHasExistingLog] = useState(false);

    const [signature, setSignature] = useState('');

    useEffect(() => {
        setLoadingSession(false);

        if (session.signature) {
            let existingSignature = session.signature;
            if (!existingSignature.startsWith('data:image/png;base64,')) {
                existingSignature = `data:image/png;base64,${existingSignature}`;
            }
            signaturePad.current.fromDataURL(existingSignature);
            signaturePad.current.off();
        }

        const canvas = signaturePad.current?.getCanvas();
        if (canvas) {
            const ctx = canvas.getContext('2d');
            ctx.canvas.width = canvas.clientWidth;
            ctx.canvas.height = canvas.clientHeight;
        }

        setHasExistingLog(session.coachingLog != null);
    }, []);

    const handleSignatureEnd = () => {
        setSignature(signaturePad.current.toDataURL());
    };

    const getSessionLocation = () => {
        if (session.location === 755730001) {
            return `Virtual / ${session.teamsLink ?? ''}`;
        }
        if (session.location === 755730000) {
            if (session.floor === null && session.roomNumber === null) {
                return 'In Person';
            }

            const floor = session.floor ? `Floor: ${session.floor}` : '';
            const roomNumber = session.roomNumber ? `Room: ${session.roomNumber}` : '';
            return `In Person / ${floor}${floor && roomNumber ? ', ' : ''}${roomNumber}`;
        }
        return '';
    };

    const isOnOrBeforeToday = (dt) => startOfDay(new Date(dt)) <= startOfToday();

    const validationSchema = hasExistingLog
        ? yup.object()
        : yup.object({
              focusArea: yup.string().required('Focus Area is required'),
              sessionSummary: yup.string().required('Session Summary is required'),
              teacherFeedbackNextSteps: yup.string().required('Teacher Feedback and Next Steps is required'),
              coachNextSteps: yup.string().required('Coach Next Steps is required'),
              anecdotalNotes: yup.string().required('Anecdotal Notes/Progress Notes is required')
          });

    const formik = useFormik({
        initialValues: {
            focusArea: session.coachingLog?.focusArea ?? '',
            sessionSummary: session.coachingLog?.sessionSummary ?? '',
            teacherFeedbackNextSteps: session.coachingLog?.teacherFeedbackNextSteps ?? '',
            coachNextSteps: session.coachingLog?.coachNextSteps ?? '',
            anecdotalNotes: session.coachingLog?.anecdotalNotes ?? ''
        },
        validationSchema,
        onSubmit: async () => {
            const { values } = formik;
            try {
                setSaving(true);
                await axios.post('/session/savecoachinglog', {
                    sessionId: session.id,
                    coachingLog: {
                        focusArea: values.focusArea,
                        sessionSummary: values.sessionSummary,
                        teacherFeedbackNextSteps: values.teacherFeedbackNextSteps,
                        coachNextSteps: values.coachNextSteps,
                        anecdotalNotes: values.anecdotalNotes
                    },
                    signature: !signaturePad.current || signaturePad.current.isEmpty() ? '' : signaturePad.current.toDataURL(),
                    hasExistingLog
                });
                closeSession();
            } catch (error) {
                showErrorAlert(dispatch, error);
                console.error(error);
                setSaving(false);
            }
        }
    });

    const { values, errors, handleChange, touched, handleSubmit } = formik;

    return (
        <MainCard sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4">Coaching Log</Typography>
                {statusMap[session.plpStatus] && (
                    <span style={{ paddingLeft: '20px' }}>
                        <Chip
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ paddingRight: '8px' }}>{statusMap[session.plpStatus].text}</span>
                                    <span style={{ position: 'relative', top: '1.5px' }}>
                                        {React.createElement(statusMap[session.plpStatus].icon, {
                                            style: { fontSize: '1.2em', margin: '0' }
                                        })}
                                    </span>
                                </div>
                            }
                            style={{
                                padding: '4px 2px 2px 7px',
                                height: '26px',
                                fontSize: '14px',
                                fontWeight: '500',
                                alignItems: 'center'
                            }}
                        />
                    </span>
                )}
            </Box>
            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <Tooltip title="Close">
                    <IconButton onClick={closeSession}>
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <form onSubmit={handleSubmit}>
                <div className="section-outline" style={{ marginTop: '24px' }}>
                    <Grid container padding={2} spacing={3}>
                        <Grid container item spacing={2} xs={6}>
                            <Grid item xs={6}>
                                <LockedTextField label="School Name" value={session.schoolName} />
                            </Grid>
                            <Grid item xs={6}>
                                <LockedTextField label="Coach Name" value={session.coachName} />
                            </Grid>
                            <Grid item xs={6}>
                                <LockedTextField label="School Address" value={session.schoolAddress} />
                            </Grid>
                            <Grid item xs={6}>
                                <LockedTextField
                                    label="Mainstream Classroom Teacher"
                                    value={session.groupParticipants?.map((participant) => participant.name).join(', ')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LockedTextField label="Location of Session" value={getSessionLocation()} />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2} xs={6}>
                            <Grid item xs={12}>
                                <LockedTextField label="Date" value={startTime.format('MM/DD/YYYY')} />
                            </Grid>
                            <Grid item xs={6}>
                                <LockedTextField label="Start Time" value={startTime.format('hh:mm A')} />
                            </Grid>
                            <Grid item xs={6}>
                                <LockedTextField label="End Time" value={endTime.format('hh:mm A')} />
                            </Grid>
                            <Grid item xs={12}>
                                <LockedTextField label="Learning Topic" value={session.learningTopic ?? ''} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <div className="section-outline" style={{ marginTop: '24px' }}>
                    <Stack padding={2} spacing={2}>
                        <LockedTextField label="Learning Plan on Agenda" multilineRows={2} value={session.learningPlan ?? ''} />
                        <LockedTextField label="Session Objective" value={session.sessionObjective ?? ''} />
                        <LockedOrUnlockedTextField
                            label="Focus Area"
                            value={values.focusArea}
                            multilineRows={2}
                            locked={hasExistingLog}
                            unlockedProps={{
                                id: 'focusArea',
                                onChange: handleChange,
                                error: Boolean(errors.focusArea && touched.focusArea),
                                helperText: Boolean(touched.focusArea) && errors.focusArea
                            }}
                        />
                        <LockedOrUnlockedTextField
                            label="Session Summary"
                            value={values.sessionSummary}
                            multilineRows={2}
                            locked={hasExistingLog}
                            unlockedProps={{
                                id: 'sessionSummary',
                                onChange: handleChange,
                                error: Boolean(errors.sessionSummary && touched.sessionSummary),
                                helperText: Boolean(touched.sessionSummary) && errors.sessionSummary
                            }}
                        />
                        <LockedOrUnlockedTextField
                            label="Teacher Feedback & Next Steps"
                            value={values.teacherFeedbackNextSteps}
                            multilineRows={2}
                            locked={hasExistingLog}
                            unlockedProps={{
                                id: 'teacherFeedbackNextSteps',
                                onChange: handleChange,
                                error: Boolean(errors.teacherFeedbackNextSteps && touched.teacherFeedbackNextSteps),
                                helperText: Boolean(touched.teacherFeedbackNextSteps) && errors.teacherFeedbackNextSteps
                            }}
                        />
                        <LockedOrUnlockedTextField
                            label="Coach Next Steps"
                            value={values.coachNextSteps}
                            multilineRows={2}
                            locked={hasExistingLog}
                            unlockedProps={{
                                id: 'coachNextSteps',
                                onChange: handleChange,
                                error: Boolean(errors.coachNextSteps && touched.coachNextSteps),
                                helperText: Boolean(touched.coachNextSteps) && errors.coachNextSteps
                            }}
                        />
                        <LockedOrUnlockedTextField
                            label="Anecdotal Notes/Progress "
                            value={values.anecdotalNotes}
                            multilineRows={2}
                            locked={hasExistingLog}
                            unlockedProps={{
                                id: 'anecdotalNotes',
                                onChange: handleChange,
                                error: Boolean(errors.anecdotalNotes && touched.anecdotalNotes),
                                helperText: Boolean(touched.anecdotalNotes) && errors.anecdotalNotes
                            }}
                        />
                    </Stack>
                </div>
                {(session.signature || (session.coachContactId === localStorage.id && isOnOrBeforeToday(startTime))) && (
                    <div className="section-outline" style={{ marginTop: '24px' }}>
                        <Stack padding={2} spacing={1}>
                            <FormLabel>Coach Signature</FormLabel>
                            <div className="signature-canvas-outline">
                                <SignatureCanvas
                                    ref={signaturePad}
                                    onEnd={handleSignatureEnd}
                                    canvasProps={{ className: 'signature-canvas' }}
                                />
                            </div>
                        </Stack>
                    </div>
                )}
                {!session.signature && (
                    <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" type="submit" disabled={saving || (hasExistingLog && !signature)}>
                            Save
                            {saving && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: theme.palette.primary.main,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px'
                                    }}
                                />
                            )}
                        </Button>
                    </div>
                )}
            </form>
        </MainCard>
    );
}
