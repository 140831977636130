// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
// assets
import { IconMenu2 } from '@tabler/icons';
import { useState } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const theme2 = createTheme({
        palette: {
            primary: {
                main: '#975fff'
            },
            secondary: {
                main: '#FF7276'
            }
        }
    });
    const [alertOpen, setAlertOpen] = useState(true);
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const handleCloseAlert = () => {
        setAlertOpen(false);
    };
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            {alertOpen && !localStorage.roles?.includes('Admin') && (
                <ThemeProvider theme={theme2}>
                    <Stack sx={{ width: '100%', paddingLeft: '43px', paddingRight: '12px', fontWeight: 'bold' }} spacing={2}>
                        <Alert severity="info" onClose={handleCloseAlert} color="primary" sx={{ fontWeight: 'bold' }}>
                            {localStorage.roles?.includes('Supervisor')
                                ? 'Please note, the Title 1 billing deadline is on the 8th of each month.'
                                : 'Please note, the portal locks weekly on Monday at 11:59 PM.'}
                        </Alert>
                    </Stack>
                </ThemeProvider>
            )}

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
