import { startOfWeek } from 'date-fns';

const getStandardBillingDeadline = () => {
    const today = new Date();
    const billingDeadline = startOfWeek(today, { weekStartsOn: 1 });
    if (today.getDay() === 1) {
        billingDeadline.setDate(billingDeadline.getDate() - 7);
    }
    return billingDeadline;
};

export default getStandardBillingDeadline;
