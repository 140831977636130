import { createContext, useState } from 'react';

const TeacherContext = createContext(null);

export const TeacherContextProvider = ({ children }) => {
    const [selectedTeacher, setSelectedTeacher] = useState({});

    return <TeacherContext.Provider value={{ selectedTeacher, setSelectedTeacher }}> {children} </TeacherContext.Provider>;
};

export default TeacherContext;
